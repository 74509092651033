import React from "react";
import et from "../../../assets/english-teacher.jpg";
import ed from "../../../assets/ed-logo.png";
import sociology from "../../../assets/sociology-zone-logo.svg";
import psycology from "../../../assets/psychology-zone-logo-blue.svg";

import { ReactComponent as MDLogo } from "../../../assets/math-logo.svg";
import { ReactComponent as EDLogo } from "../../../assets/english-logo.svg";
import { ReactComponent as SDLogo } from "../../../assets/science-logo.svg";
import config from "config";

const AcrossCard = () => {
  let friendCards = [];

  if (config["class-name"] === "math" || config["class-name"] === "english") {
    friendCards.push({
      name: "Science Directory",
      link: "https://sciencedirectory.co.uk",
      img: SDLogo,
    });
  }

  if (
    config["class-name"] === "science" ||
    config["class-name"] === "english"
  ) {
    friendCards.push({
      name: "Maths Directory",
      link: "https://mathsdirectory.co.uk",
      img: MDLogo,
    });
  }

  if (config["class-name"] === "science" || config["class-name"] === "math") {
    friendCards.push({
      name: "English Directory",
      link: "https://englishdirectory.co.uk",
      img: EDLogo,
    });
  }

  return (
    <div className="across-card">
      <div className="title">We are friends with</div>
      <div className="content-6">
        <a
          href="https://www.englishteacher.co.uk"
          className="card-anchor"
          target="_blank"
        >
          <div className="icon">
            <img src={et} alt="english-teacher" style={{ width: "80px" }} />
            <div>www.englishteacher.co.uk</div>
          </div>
        </a>
        <a
          href="https://www.englishdepartment.co.uk"
          className="card-anchor"
          target="_blank"
        >
          <div className="icon">
            <img
              src={ed}
              alt=""
              style={{ maxWidth: "220px", height: "37px" }}
            />
            <div>www.englishdepartment.co.uk</div>
          </div>
        </a>
        <a
          href="https://www.facebook.com/groups/810857092987367"
          className="card-anchor"
          target="_blank"
        >
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="38"
              viewBox="0 0 76 48"
              fill="none"
            >
              <g clipPath="url(#clip0_21_251)">
                <path
                  d="M28.7443 44.1352C28.7443 42.3186 27.2927 40.9559 25.4936 40.9559C23.6944 40.9559 22.2031 42.3824 22.2031 44.1115C22.2031 45.8406 23.6628 47.2589 25.4936 47.2589C26.0145 47.2589 26.575 47.1231 27.0011 46.8843L28.1449 48L28.942 47.1951L27.782 46.3265C28.3498 45.7768 28.7443 44.9802 28.7443 44.1352ZM25.4376 46.2463C24.3173 46.2463 23.4019 45.2099 23.4019 44.0787C23.4019 42.9475 24.3408 41.9749 25.4927 41.9749C26.6446 41.9749 27.5518 42.8992 27.5518 44.1261C27.5518 45.353 26.613 46.2454 25.4367 46.2454L25.4376 46.2463Z"
                  fill="#FF90A0"
                />
                <path
                  d="M32.0039 46.2153C31.9091 46.2873 31.6879 46.4067 31.3413 46.4067C30.8755 46.4067 30.434 46.2235 30.434 45.4268V43.0524H29.4004V45.6657C29.4004 46.7175 30.1578 47.2435 31.2311 47.2435C31.9732 47.2435 32.5645 47.0922 33.0384 46.6774V43.0524H32.0048V46.2153H32.0039Z"
                  fill="#FF90A0"
                />
                <path
                  d="M35.5844 42.9323C34.9371 42.9323 34.4397 43.1 34.0768 43.275L34.3765 44.0078C34.5345 43.9358 34.9759 43.769 35.4968 43.769C36.0493 43.769 36.2939 43.9996 36.2939 44.3351V44.7498C36.0646 44.5748 35.56 44.4791 35.2837 44.4791C34.5264 44.4791 33.6426 44.83 33.6426 45.7616C33.6426 46.55 34.2104 47.2437 35.4498 47.2437C36.3806 47.2437 36.9728 46.9885 37.2806 46.7414V44.5821C37.2806 43.5785 36.9249 42.9332 35.5844 42.9332V42.9323ZM36.2948 46.3021C36.1134 46.406 35.8452 46.4698 35.5293 46.4698C35.0247 46.4698 34.7087 46.1991 34.7087 45.7771C34.7087 45.4188 35.0635 45.2192 35.458 45.2192C35.7893 45.2192 36.1287 45.3705 36.2948 45.5136V46.3021Z"
                  fill="#FF90A0"
                />
                <path
                  d="M39.1664 40.5895H38.1328V47.1232H39.1664V40.5895Z"
                  fill="#FF90A0"
                />
                <path
                  d="M41.1469 43.0514H40.1133V47.1231H41.1469V43.0514Z"
                  fill="#FF90A0"
                />
                <path
                  d="M40.6277 41.068C40.2567 41.068 39.9805 41.3305 39.9805 41.7215C39.9805 42.1126 40.2567 42.3751 40.6277 42.3751C40.9987 42.3751 41.275 42.1126 41.275 41.7215C41.275 41.3305 41.0222 41.068 40.6277 41.068Z"
                  fill="#FF90A0"
                />
                <path
                  d="M44.1625 41.3305C44.3674 41.3305 44.5101 41.3861 44.6599 41.4581L45.0779 40.7089C44.7466 40.5092 44.3042 40.4454 44.0045 40.4454C42.9231 40.4454 42.45 41.2822 42.45 42.1746V43.0587H41.7793V43.8791H42.45V47.1222H43.4837V43.8791H44.4938V43.0587H43.4837V42.1828C43.4837 41.6404 43.6813 41.3305 44.1625 41.3305Z"
                  fill="#FF90A0"
                />
                <path
                  d="M46.1821 43.0514H45.1484V47.1231H46.1821V43.0514Z"
                  fill="#FF90A0"
                />
                <path
                  d="M45.6609 41.3068C45.298 41.3068 45.0137 41.6012 45.0137 41.9603C45.0137 42.3194 45.3215 42.6139 45.6609 42.6139C46.0004 42.6139 46.3082 42.3349 46.3082 41.9603C46.3082 41.5857 46.0166 41.3068 45.6609 41.3068Z"
                  fill="#FF90A0"
                />
                <path
                  d="M49.0621 42.932C47.8073 42.932 46.9082 43.8563 46.9082 45.0996C46.9082 46.4067 47.8082 47.2425 49.1416 47.2425C50.0407 47.2425 50.6563 46.9235 50.8694 46.7403L50.5697 46.0156C50.4198 46.1587 49.9856 46.414 49.37 46.414C48.7543 46.414 48.0601 46.239 47.95 45.4815H51.0192V45.0832C51.0192 43.9356 50.372 42.932 49.0621 42.932ZM47.95 44.7003C48.0682 44.1024 48.479 43.7442 49.0702 43.7442C49.6308 43.7442 49.9775 44.1826 49.9856 44.7003H47.95Z"
                  fill="#FF90A0"
                />
                <path
                  d="M54.436 40.5895V43.1709C54.1833 43.0041 53.8682 42.9321 53.5838 42.9321C52.3453 42.9321 51.4688 43.8399 51.4688 45.1388C51.4688 46.517 52.5574 47.2426 53.694 47.2426C54.2699 47.2426 54.9009 47.0995 55.4697 46.7485V40.5895H54.436ZM54.436 46.2144C54.2067 46.3575 53.9151 46.3894 53.694 46.3894C53.0864 46.3894 52.5574 45.8954 52.5574 45.1388C52.5574 44.3823 52.9682 43.7998 53.6145 43.7998C53.9142 43.7998 54.2537 43.9192 54.4351 44.1106V46.2144H54.436Z"
                  fill="#FF90A0"
                />
                <path
                  d="M2.38321 41.3305C2.58813 41.3305 2.73076 41.3861 2.88061 41.4581L3.29858 40.7089C2.96728 40.5092 2.52494 40.4454 2.22523 40.4454C1.14376 40.4454 0.670729 41.2822 0.670729 42.1746V43.0587H0V43.8791H0.670729V47.1222H1.70436V43.8791H2.71451V43.0587H1.70436V42.1828C1.70436 41.6404 1.90205 41.3305 2.38321 41.3305Z"
                  fill="#212121"
                />
                <path
                  d="M3.88359 41.3068C3.52069 41.3068 3.23633 41.6012 3.23633 41.9603C3.23633 42.3194 3.54416 42.6139 3.88359 42.6139C4.22301 42.6139 4.53084 42.3349 4.53084 41.9603C4.53084 41.5857 4.23836 41.3068 3.88359 41.3068Z"
                  fill="#212121"
                />
                <path
                  d="M4.40277 43.0514H3.36914V47.1231H4.40277V43.0514Z"
                  fill="#212121"
                />
                <path
                  d="M7.6615 42.932C7.04584 42.932 6.58093 43.2747 6.38324 43.4661V43.0514H5.34961V47.1231H6.38324V44.3029C6.49337 44.1352 6.88786 43.849 7.24354 43.849C7.86733 43.849 7.95399 44.2792 7.95399 44.9483V47.1231H8.98761V44.3977C8.98761 43.3777 8.52181 42.932 7.6615 42.932Z"
                  fill="#212121"
                />
                <path
                  d="M12.6255 43.1709C12.3727 43.0041 12.0577 42.9321 11.7733 42.9321C10.5348 42.9321 9.6582 43.8399 9.6582 45.1388C9.6582 46.517 10.7469 47.2426 11.8834 47.2426C12.4594 47.2426 13.0904 47.0995 13.6591 46.7485V40.5895H12.6255V43.1709ZM12.6255 46.2144C12.3962 46.3575 12.1046 46.3894 11.8834 46.3894C11.2759 46.3894 10.7469 45.8954 10.7469 45.1388C10.7469 44.3823 11.1576 43.7998 11.804 43.7998C12.1037 43.7998 12.4431 43.9192 12.6246 44.1106V46.2144H12.6255Z"
                  fill="#212121"
                />
                <path
                  d="M18.0288 42.9323C17.3815 42.9323 16.885 43.1 16.5221 43.275L16.8218 44.0078C16.9798 43.9358 17.4212 43.769 17.9421 43.769C18.4946 43.769 18.7392 43.9996 18.7392 44.3351V44.7498C18.5099 44.5748 18.0053 44.4791 17.7291 44.4791C16.9717 44.4791 16.0879 44.83 16.0879 45.7616C16.0879 46.55 16.6557 47.2437 17.8952 47.2437C18.8259 47.2437 19.4181 46.9885 19.7259 46.7414V44.5821C19.7259 43.5785 19.3702 42.9332 18.0288 42.9332V42.9323ZM18.7392 46.3021C18.5578 46.406 18.2897 46.4698 17.9737 46.4698C17.4691 46.4698 17.1531 46.1991 17.1531 45.7771C17.1531 45.4188 17.5079 45.2192 17.9024 45.2192C18.2337 45.2192 18.5731 45.3705 18.7392 45.5136V46.3021Z"
                  fill="#212121"
                />
                <path
                  d="M59.6353 41.6815H58.6017V43.0597H57.8362V43.88H58.6017V47.1232H59.6353V43.88H60.6301V43.0597H59.6353V41.6815Z"
                  fill="#212121"
                />
                <path
                  d="M63.7226 46.2153C63.6278 46.2873 63.4067 46.4067 63.06 46.4067C62.5942 46.4067 62.1528 46.2235 62.1528 45.4268V43.0524H61.1191V45.6657C61.1191 46.7175 61.8765 47.2435 62.9499 47.2435C63.6919 47.2435 64.2832 47.0922 64.7571 46.6774V43.0524H63.7235V46.2153H63.7226Z"
                  fill="#212121"
                />
                <path
                  d="M67.0999 41.6815H66.0654V43.0597H65.3008V43.88H66.0654V47.1232H67.0999V43.88H68.0938V43.0597H67.0999V41.6815Z"
                  fill="#212121"
                />
                <path
                  d="M70.5165 42.9323C69.2617 42.9323 68.3066 43.8966 68.3066 45.0998C68.3066 46.303 69.2617 47.2428 70.4922 47.2428C71.7226 47.2428 72.6777 46.2784 72.6777 45.0998C72.6777 43.9212 71.747 42.9323 70.5156 42.9323H70.5165ZM70.4849 46.4297C69.9171 46.4297 69.3881 45.8801 69.3881 45.1226C69.3881 44.3259 69.8693 43.7763 70.5165 43.7763C71.1006 43.7763 71.5899 44.3259 71.5899 45.0989C71.5899 45.8719 71.1484 46.4297 70.4849 46.4297Z"
                  fill="#212121"
                />
                <path
                  d="M75.2745 42.9401C74.8006 42.9401 74.4855 43.4897 74.3907 43.6966V43.0513H73.3652V47.123H74.3907V44.8761C74.3907 44.4696 74.6354 43.9117 75.0931 43.9117C75.3458 43.9117 75.5192 44.0311 75.6455 44.1186L76.0003 43.1388C75.8342 43.0431 75.6374 42.9401 75.2745 42.9401Z"
                  fill="#212121"
                />
                <path
                  d="M6.98082 9.58177V11.9034H12.0388V15.5339H6.98082V30.0542H2.2153V15.5339H0V11.9034H2.2153V8.94827C2.2153 2.65978 5.39291 0 9.65651 0C11.2868 0 12.7917 0.380099 13.8786 1.0136L13.3767 4.93855C12.6247 4.47459 11.8294 4.26312 10.9104 4.26312C8.31866 4.26312 6.98082 5.86738 6.98082 9.58177Z"
                  fill="#212121"
                />
                <path
                  d="M27.4625 27.3281C26.8179 27.669 25.8664 27.855 24.9763 27.855C23.9328 27.855 22.8892 27.576 22.3674 26.3984C21.3239 27.1732 19.8813 27.855 17.9477 27.855C15.0625 27.855 13.3438 26.3674 13.3438 24.012C13.3438 21.0679 15.9219 19.8902 19.2982 19.8902C20.3417 19.8902 21.2318 20.0142 22.0298 20.2311V19.0844C22.0298 17.5039 21.2625 16.6051 19.2061 16.6051C17.4259 16.6051 16.0447 17.2559 14.8477 17.9997L14.4794 15.3035C15.7685 14.5287 17.4873 13.8159 19.8199 13.8159C22.613 13.8159 25.4981 14.8386 25.4981 18.8675V23.5162C25.4981 24.9728 25.9278 25.7476 27.2783 25.2827L27.4625 27.3281ZM19.0833 25.2827C20.1883 25.2827 21.2318 24.8178 22.0298 24.229V22.4935C21.2625 22.3075 20.5566 22.2146 19.8506 22.2146C18.0704 22.2146 16.9655 22.8344 16.9655 23.8571C16.9655 24.7558 17.8863 25.2827 19.0833 25.2827Z"
                  fill="#212121"
                />
                <path
                  d="M76.0001 29.1254C74.4952 30.1809 72.1536 30.5182 70.6072 30.5182C66.6361 30.5182 64.2113 28.45 64.2113 23.722V15.533H61.4102V11.9025H64.2113V7.13253L68.9768 6.62573V11.9025H75.1642V15.533H68.9768V22.4978C68.9768 24.9461 69.7288 26.6342 72.3206 26.6342C73.4074 26.6342 74.7038 26.2541 75.6652 25.7054L76.0001 29.1245V29.1254Z"
                  fill="#212121"
                />
                <path
                  d="M51.1054 30.7807L49.6105 23.7785C48.965 25.8057 47.3166 27.5066 44.8793 28.0371C40.3476 29.0233 36.4189 26.1056 35.2869 20.8015C34.1395 15.4282 36.5507 11.2398 41.1853 10.2308C43.657 9.69298 46.0104 10.4504 47.258 11.9198C47.1623 9.61914 48.4053 8.87718 49.5374 8.63016C50.5331 8.41322 51.4457 8.65021 51.4755 8.78876L55.6651 28.4117C57.9147 25.6881 59.2688 22.1852 59.2688 18.3614C59.2688 9.68842 52.306 2.65796 43.7166 2.65796C35.1271 2.65796 28.1643 9.68842 28.1643 18.3614C28.1643 27.0344 35.1271 34.0649 43.7166 34.0649C46.5746 34.0649 49.2512 33.2828 51.5523 31.9237C51.3609 31.633 51.2074 31.2583 51.1054 30.7798V30.7807Z"
                  fill="#FF90A0"
                />
                <path
                  d="M43.1891 13.8933C40.5802 14.4611 39.236 16.749 39.8878 19.7989C40.517 22.7458 42.7386 24.4021 45.2446 23.857C47.5448 23.3566 49.1119 21.2738 48.6019 18.882L47.9284 15.7272C46.8524 14.2205 45.2491 13.4448 43.19 13.8933H43.1891Z"
                  fill="#FF90A0"
                />
              </g>
              <defs>
                <clipPath id="clip0_21_251">
                  <rect width="76" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div>Find a Qualified tutor</div>
          </div>
        </a>
        <a
          href="https://www.psychologyzone.co.uk"
          className="card-anchor"
          target="_blank"
        >
          <div className="icon">
            <img src={sociology} alt="" style={{ maxWidth: "160px" }} />
            <div>www.sociologyzone.co.uk</div>
          </div>
        </a>
        <a
          href="https://www.sociologyzone.co.uk"
          className="card-anchor"
          target="_blank"
        >
          <div className="icon">
            <img src={psycology} alt="" style={{ maxWidth: "160px" }} />
            <div>www.psychologyzone.co.uk</div>
          </div>
        </a>

        {friendCards.map((card, index) => {
          return (
            <a
              href={card.link}
              className="card-anchor"
              target="_blank"
              key={index}
            >
              <div className="icon">
                <div style={{ margin: 0, maxWidth: "320px", height: "57px" }}>
                  <card.img />
                </div>
                <div>{card.name}</div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default AcrossCard;
