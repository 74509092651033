import { isProfileCompleted } from "../../../../utils";

export const getStatus = (
  profileUser: any,
  status: number,
  accountStatus: number,
  _id: string
) => {
  if (!_id) {
    return "Not Created";
  }

  if (status === 0 && accountStatus === 0) {
    if (isProfileCompleted(profileUser) && !profileUser?.isSubmitted) {
      return "Completed";
    } else if (profileUser?.isSubmitted) {
      return "Pending";
    } else if (!profileUser?.isSubmitted) {
      return "Draft";
    }
  }

  return "Live";
};
