export const getCameraPermissionState = async () => {
  return navigator.permissions.query({ name: 'camera' }).then((result) => {
    return result.state;
  }).catch((error) => {
    console.error('Error getting camera permission state:');
    return null
  })
}

export const checkAndRequestCameraPermission = async () => {
  const permissionState = await getCameraPermissionState();
  if (permissionState === 'granted') {
    return true;
  } else if (permissionState === 'prompt') {
    return navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      return true;
    }).catch((error) => {
      return false;
    });
  } else {
    return false;
  }
}