import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as MDLogo} from '../../assets/math-logo.svg';
import {ReactComponent as EDLogo} from '../../assets/english-logo.svg';
import {ReactComponent as SDLogo} from '../../assets/science-logo.svg';
import './navbar.css';
import {useDispatch, useSelector} from 'react-redux';
import {logout} from '../../store/main';
import {initProfile} from '../../store/setProfile';
import {API_URL} from '../../api';
import config from 'config';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  Link as MuiLink,
  Menu,
  MenuItem,
} from '@mui/material';
import {KeyboardArrowDown} from '@mui/icons-material';
import sd
  from '../../assets/sciencedirectory-logo/sciencedirectory-logo-icon.png';
import ed
  from '../../assets/englishdirectory-logo/englishdirectory-20240318-logo-icon.png';
import md from '../../assets/logos/md-logo-icon.png';

const Navbar = () => {
  let Logo = MDLogo;
  if (config["class-name"] === "english") {
    Logo = EDLogo;
  }
  if (config["class-name"] === "science") {
    Logo = SDLogo;
  }

  const parentsSignUpStyle = {
    variant: "outlined",
    color: "secondary",
  };

  if (config["class-name"] === "math") {
    parentsSignUpStyle.color = "success";
    parentsSignUpStyle.variant = "contained";
  }

  const dispatch = useDispatch();
  const user = useSelector((state) => state.main);
  const isAuth = useSelector((state) => !!state.main.userId);
  const { firstName, lastName, isGuest } = useSelector((state) => state.main);
  const { avatar: tutorAvatar, gender } = useSelector(
    (state) => state.profile.user
  );
  const { _id } = useSelector((state) => state.profile.user);
  const { avatar: guestAvatar } = useSelector(
    (state) => state.profile.guestInformation
  );

  const unreadMessageCount = useSelector(
    (state) =>
      state.profile?.messages?.filter((message) => !message.isRead).length
  );
  const fullName = isAuth
    ? `${firstName.charAt(0).toUpperCase() + firstName.slice(1)} ${
        lastName.length > 0 ? lastName.charAt(0).toUpperCase() + "." : ""
      }`
    : "";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickOtherDirectory = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const maleAvatar = "/assets/image/user/male.png";
  const femaleAvatar = "/assets/image/user/female.png";
  const userAvatar = "/assets/image/user/User-avatar.svg.png";
  const avatar =
    isGuest && !guestAvatar?.includes("temp") ? guestAvatar : tutorAvatar;
  const defaultAvatar =
    gender === 1 ? femaleAvatar : gender === 0 ? maleAvatar : userAvatar;
  const [menuOpen, setMenuOpen] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const profileMenuRef = useRef(null);

  const sixtyDaysLater = user.createdAt + 5184000000;
  const currentDate = Date.now();
  const isExpired = sixtyDaysLater < currentDate;

  const navigate = useNavigate();
  const location = useLocation();
  const handleFindTutor = () => {
    if (location.pathname === "/search") {
      window.location.reload();
    } else {
      navigate("/search");
    }
  };

  useEffect(() => {
    // Attach event listener to document body for clicking outside the profileMenu
    const handleOutsideClick = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setShowProfileMenu(false);
      }
    };

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleClick = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const logoutHandle = () => {
    dispatch(logout());
    dispatch(initProfile());
  };

  const linkStyle = {
    textDecoration: "none",
    color: "inherit",
  };

  return (
    <div className="navbar" id="navbar">
      <div className="logo" id="logo">
        <Link to="/">
          <Logo style={{ height: "75px" }} />
        </Link>
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <ul className="nav-container">
          <li>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              endIcon={<KeyboardArrowDown />}
              onClick={handleClickOtherDirectory}
              color="inherit"
              style={{
                fontWeight: "bold",
                textTransform: "none",
                letterSpacing: "normal",
                fontSize: "16px",
              }}
            >
              Other Directories
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {config["class-name"] === "science" ||
              config["class-name"] === "math" ? (
                <MenuItem>
                  <a
                    href={"https://englishdirectory.co.uk/"}
                    target="_blank"
                    style={linkStyle}
                  >
                    <img
                      src={ed}
                      style={{ width: "20px", marginRight: "10px" }}
                    />
                    <span>English Directory</span>
                  </a>
                </MenuItem>
              ) : (
                ""
              )}

              {config["class-name"] === "math" ||
              config["class-name"] === "english" ? (
                <MenuItem>
                  <a
                    href={"https://sciencedirectory.co.uk/"}
                    target="_blank"
                    style={linkStyle}
                  >
                    <img
                      src={sd}
                      style={{ width: "20px", marginRight: "10px" }}
                    />
                    <span>Science Directory</span>
                  </a>
                </MenuItem>
              ) : (
                ""
              )}

              {config["class-name"] === "science" ||
              config["class-name"] === "english" ? (
                <MenuItem>
                  <a
                    href={"https://mathsdirectory.co.uk/"}
                    target="_blank"
                    style={linkStyle}
                  >
                    <img
                      src={md}
                      style={{ width: "20px", marginRight: "10px" }}
                    />
                    <span>Maths Directory</span>
                  </a>
                </MenuItem>
              ) : (
                ""
              )}
            </Menu>
          </li>
          <li className={"hidden-md"}>
            <Link className="nav-item link" to="/">
              Home
            </Link>
          </li>
          {/* <li className={'hidden-md'}>
          <span className="nav-item link" style={{cursor: "pointer"}} onClick={() => {
            handleFindTutor()
          }}>
            Home
          </span>
          </li> */}
          <li>
            <span
              className="nav-item link"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleFindTutor();
              }}
            >
              {config["navbar-link"]}
            </span>
          </li>
          <li className={"hidden-md"}>
            <Link className="nav-item link" to="/contact">
              Contact us
            </Link>
          </li>
          {isAuth ? (
            <li
              className="profile explore-more"
              onClick={handleClick}
              ref={profileMenuRef}
            >
              <span className="name">
                <strong>Nicholas teacher</strong>
              </span>
              <div
                className="avatar"
                style={{
                  backgroundImage: `url(${
                    avatar ? `${API_URL}${avatar}` : defaultAvatar
                  })`,
                  border: unreadMessageCount ? "2px solid #f00" : "none",
                }}
              ></div>
              {showProfileMenu && (
                <div className="profile-menu">
                  <ul>
                    <li>
                      <Link to="/dashboard" className="link">
                        {fullName}
                      </Link>
                    </li>
                    <hr />
                    <li>
                      <Link to="/dashboard" className="link">
                        Dashboard
                      </Link>
                    </li>
                    {isAuth && !isGuest && (
                      <li>
                        <Link
                          to="/profile?step=0"
                          className={!_id ? "link red" : "link"}
                        >
                          {!_id ? "Create Tutor profile" : "Tutor profile"}
                        </Link>
                      </li>
                    )}

                    <li>
                      <Link to="/dashboard?tab=message" className="link">
                        {unreadMessageCount > 0 ? (
                          <Badge
                            badgeContent={unreadMessageCount}
                            color="error"
                          >
                            Messages
                          </Badge>
                        ) : (
                          "Messages"
                        )}
                      </Link>
                    </li>
                    {/*{*/}
                    {/*  // !isExpired && user.billedAt === undefined &&*/}
                    {/*    isExpired &&*/}
                    {/*    <li>*/}
                    {/*      <Link to="/billing">Subscription</Link>*/}
                    {/*    </li>*/}
                    {/*}*/}
                    <li>
                      <Link
                        to="/"
                        onClick={() => {
                          logoutHandle();
                        }}
                        className="link"
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
          ) : (
            <>
              <li className={"hidden-md"}>
                <MuiLink
                  component={Link}
                  className="nav-item link sign-in"
                  to="/signin"
                >
                  Sign in
                </MuiLink>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/signup?tutor"
                  onClick={() => setMenuOpen(!menuOpen)}
                  variant="contained"
                  color="primary"
                  className="nav-item join"
                >
                  Sign Up
                  {/*<Box*/}
                  {/*  style={{*/}
                  {/*    position: "absolute",*/}
                  {/*    top: -13,*/}
                  {/*    right: -1,*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <Chip*/}
                  {/*    label="It's Free!"*/}
                  {/*    color="warning"*/}
                  {/*    sx={{*/}
                  {/*      backgroundColor: config["chip-colour"],*/}
                  {/*      p: "2px",*/}
                  {/*      boxShadow: 1,*/}
                  {/*      borderRadius: "2px",*/}
                  {/*    }}*/}
                  {/*    size="small"*/}
                  {/*    variant="filled"*/}
                  {/*  />*/}
                  {/*</Box>*/}
                </Button>
              </li>
            </>
          )}
        </ul>
        {/* Mobile menu  */}
        <div className={"right " + (menuOpen && "active")}>
          <div
            className="hamburger explore-more-mobile "
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>

      <div className={"menu " + (menuOpen && "active")}>
        <ul>
          {isAuth && (
            <li className="full-name">
              <Link
                className="nav-item link"
                to="/dashboard"
                onClick={() => setMenuOpen(!menuOpen)}
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <div
                  className="avatar"
                  style={{
                    backgroundImage: `url(${
                      avatar ? `${API_URL}${avatar}` : defaultAvatar
                    })`,
                    display: "inline-block",
                    justifyContent: "flex-start",
                    border: unreadMessageCount ? "2px solid #f00" : "none",
                  }}
                ></div>
                {fullName}
              </Link>
            </li>
          )}
          <li>
            <Link
              className="nav-item link"
              to="/"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              Home
            </Link>
          </li>
          {isAuth && (
            <>
              <li>
                <Link
                  className="nav-item link"
                  to="/dashboard"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  className="nav-item link"
                  to="/dashboard?tab=message"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  {unreadMessageCount > 0 ? (
                    <Badge badgeContent={unreadMessageCount} color="error">
                      Messages
                    </Badge>
                  ) : (
                    "Messages"
                  )}
                </Link>
              </li>
              {/*<li>*/}
              {/*  <Link*/}
              {/*    className="nav-item"*/}
              {/*    to="/billing"*/}
              {/*    onClick={() => setMenuOpen(!menuOpen)}*/}
              {/*  >*/}
              {/*    <span>Subscription</span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
            </>
          )}

          {isAuth && !isGuest && (
            <li>
              <Link
                onClick={() => setMenuOpen(!menuOpen)}
                to="/profile?step=0"
                className={!_id ? "nav-item link red" : "nav-item link"}
              >
                {!_id ? "Create Tutor profile" : "Tutor profile"}
              </Link>
            </li>
          )}
          <li>
            <Link
              className="nav-item link"
              to="/search"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {config["navbar-link"]}
            </Link>
          </li>
          <li>
            <Link
              className="nav-item link"
              to="/contact"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              Contact us
            </Link>
          </li>
        </ul>
        <ul>
          {isAuth ? (
            <li>
              <Link
                className="nav-item link"
                to="/"
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  logoutHandle();
                }}
              >
                Log out
              </Link>
            </li>
          ) : (
            <>
              <li className={"hidden-md"}>
                <Link
                  className="nav-item link"
                  to="/signup?tutor"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Sign Up
                  {/*<Box*/}
                  {/*  style={{*/}
                  {/*    display: "flex",*/}
                  {/*    justifyContent: "space-between",*/}
                  {/*    alignItems: "center",*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <Chip*/}
                  {/*    label="It's Free!"*/}
                  {/*    color="warning"*/}
                  {/*    sx={{*/}
                  {/*      backgroundColor: config["chip-colour"],*/}
                  {/*      p: "2px",*/}
                  {/*      boxShadow: 1,*/}
                  {/*      borderRadius: "2px",*/}
                  {/*    }}*/}
                  {/*    size="small"*/}
                  {/*    variant="filled"*/}
                  {/*  />*/}
                  {/*</Box>*/}
                </Link>
              </li>
              {config["class-name"] === "science" ||
              config["class-name"] === "math" ? (
                <>
                  <li className={"hidden-md"}>
                    <a
                      href={"https://englishdirectory.co.uk/"}
                      target="_blank"
                      style={linkStyle}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>English Directory</span>
                        <img
                          src={ed}
                          style={{ width: "20px", marginRight: "10px" }}
                        />
                      </Box>
                    </a>
                  </li>
                </>
              ) : (
                ""
              )}
              {config["class-name"] === "math" ||
              config["class-name"] === "english" ? (
                <>
                  <li className={"hidden-md"}>
                    <a
                      href={"https://sciencedirectory.co.uk/"}
                      target="_blank"
                      style={linkStyle}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>Science Directory</span>
                        <img
                          src={sd}
                          style={{ width: "20px", marginRight: "10px" }}
                        />
                      </Box>
                    </a>
                  </li>
                </>
              ) : (
                ""
              )}
              {config["class-name"] === "science" ||
              config["class-name"] === "english" ? (
                <>
                  <li className={"hidden-md"}>
                    <a
                      href={"https://mathsdirectory.co.uk/"}
                      target="_blank"
                      style={linkStyle}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>Maths Directory</span>
                        <img
                          src={md}
                          style={{ width: "20px", marginRight: "10px" }}
                        />
                      </Box>
                    </a>
                  </li>
                </>
              ) : (
                ""
              )}
              <li>
                <Link
                  className="nav-item link"
                  to="/signin"
                  onClick={() => {
                    setMenuOpen(!menuOpen);
                  }}
                >
                  Sign in
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
