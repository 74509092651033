import { createTheme, lighten } from '@mui/material/styles';
import config from 'config';

const theme = createTheme({
  palette: {
    primary: {
      main: config['primary-colour-mui'],
    },
    secondary: {
      main: config['secondary-colour-mui'],
    },
    success: {
      main: '#00ab55',
    }
    
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Custom styles for buttons
          textTransform: 'none', // Example of removing text transform
         
        },
        containedPrimary: {
          // Styles specifically for contained primary buttons
          backgroundColor: config['primary-colour-mui'],
          color: '#ffffff', // Ensures white text
        },
        containedSecondary: {
          // Styles specifically for contained secondary buttons
          backgroundColor: config['secondary-colour-mui'],
          '&:hover': {
            backgroundColor: lighten(config['secondary-colour-mui'], 0.2), // Adjust the lighten factor as needed
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: config['secondary-colour-mui'], // Thumb color
        },
        track: {
          color: config['secondary-colour-mui'], // Track color
        },
        rail: {
          color: lighten(config['secondary-colour-mui'], 0.5), // Rail color
        },
      },
    },
  },
});

export default theme;