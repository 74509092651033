import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import config from "config";

const colourClassName = config['class-name'] === "math" ? "primary-colour" : "secondary-colour";

const CssTextField = styled(TextField)(() => ({
    '& label.Mui-focused': {
        color: config[colourClassName],
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: config[colourClassName],
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: config[colourClassName],
        },
        '&.Mui-focused fieldset': {
            borderColor: config[colourClassName],
        },
    },
    '& .MuiOutlinedInput-root:hover': {
        '& fieldset': {
            borderColor: '#5A5A5A',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#5A5A5A',
        },
    },
}));

export default CssTextField;