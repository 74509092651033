import React from 'react';
import Home from './Home/Home';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
// import AboutUs from './AboutUs';
// import LinearProgress from '@mui/material/LinearProgress';
import SignUp from './Signup/SignUp';
import SignIn from './Signin/SignIn';
import Profile from './Profile/Profile';
import Search from './Search/Search';
import Contact from './Contact/Contact';
import TutorView from './TutorView/TutorView';
import DashBoard from './Dashboard/Dashboard';
import { useSelector } from 'react-redux';
// import Billing from './Billing/Billing';
import Terms from './Terms';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import Cookies from './Cookies'

const ProtectedRoute = ({ children, redirect }) => {
    const isAuth = useSelector(state => !!state.main.userId);

    return isAuth ? children : <Navigate to={{ pathname: '/signin', search: `?redirect=${redirect}` }} replace />;
};

// ... Rest of your code
const Routing = () => {
  // const cookieValue = Cookies.get('token');
  const isAuth = useSelector(state => !!state.main.userId);
  const location = useLocation()
  const currentURL = location.pathname + location.search;

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path='/signin' element={<SignIn />} />
      <Route path='/forgot_password' element={<ForgotPassword />} />
      <Route
        path="/profile"
        element={
          <ProtectedRoute redirect={currentURL}>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route path='/search' element={<Search />} />
      <Route path='/tutor/:tutorId' element={<TutorView />} />
      <Route path="/contact" element={<Contact />} />
      {/*<Route path="/about" element={<AboutUs />} />*/}
      <Route path="/terms" element={<Terms />} />
      <Route path="/cookies-policy" element={<Cookies />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute redirect={currentURL}>
            <DashBoard />
          </ProtectedRoute>
        }
      />
      {/*<Route*/}
      {/*  path="/billing"*/}
      {/*  element={*/}
      {/*    <ProtectedRoute isAuthenticated={isAuth} redirect={currentURL}>*/}
      {/*      <Billing />*/}
      {/*    </ProtectedRoute>*/}
      {/*  }*/}
      {/*/>*/}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Routing;