import React from 'react'
import { Link } from 'react-router-dom'
import './footer.css'
import config from 'config'

const Footer = () => {
  var year = new Date().getFullYear()

  return (
    <div className="footer">
      <p>Copyright © 2023-{year} {config['footer-content']}. All rights reserved.</p>
      <span style={{ display: 'inline-flex', gap: '1rem' }}>
        <Link className="pink" to="/terms" target="_blank">
          Terms of use
        </Link>
        <Link className="pink" to="/cookies-policy" target="_blank">
          Cookies Policy
        </Link>
        {/*<Link className="pink" to="/about">*/}
        {/*  About us*/}
        {/*</Link>*/}
      </span>
    </div>
  )
}

export default Footer
