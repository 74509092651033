import React, { useEffect, useMemo, useState } from 'react'
import './Search.css'
import FilterBox from './Components/FilterBox'
import ResultBox from './Components/ResultBox'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTutors } from '../../store/search'

const Search = () => {
    const { filters } = useSelector((state) => state.search);
    const {
        level,
        gender,
        isOnline,
        price,
        distance,
        exam,
        latitude,
        longitude,
    } = filters
    const [loading, setLoading] = useState(true)
    // const mathLevel = filters.level;

  const dispatch = useDispatch()
  // const filterParams = useMemo(() => {
  //   return {
  //     price: '15,100',
  //     level: mathLevel ?? undefined
  //   }
  // }, []);

  useEffect(() => {
      let filterParams = {}
      if (level) filterParams.level = level
      if (isOnline > -1) filterParams.isOnline = isOnline
      if (isOnline === 0) {
          filterParams.distance = distance
          filterParams.latitude = latitude
          filterParams.longitude = longitude
      }
      if (gender > -1) filterParams.gender = gender
      filterParams.price = price.join(',')
      if (exam) filterParams.exam = exam
      dispatch(fetchTutors({filterParams, setLoading}))
  }, [])

  return (
    <div className='search-container'>
      <FilterBox setLoading={setLoading} />
      <ResultBox loading={loading} />
    </div>
  );
};

export default Search;
