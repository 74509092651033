import React, {useEffect, useState} from 'react';
import './SignIn.css';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { initLogin, login } from '../../store/main';
import { TextField } from '@mui/material';
import config from 'config';
import axios from "axios";
import {API_URL} from "../../api";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAuth = useSelector(state => !!state.main.userId)

  // Access individual query parameters by name
  const redirect = queryParams.get('redirect');
  const guest = queryParams.get('guest');

  const [user, setUser] = useState({
    email: '',
    password: '',
    isGuest: false
  });

  useEffect(() => {
    if(isAuth) navigate(redirect ?? '/')
  }, [isAuth, redirect, navigate])
  useEffect(() => {
    if (guest !== null) setUser({ ...user, isGuest: true });
  }, [guest, user]);

  const createUser = (user) => {
    if (waiting) return;

    const showErrorAlert = (text) => {
      SweetAlert.fire({
        imageUrl: '/assets/error-icon.png',
        imageHeight: '100px',
        title: 'Oops...',
        text: text,
        confirmButtonColor: '#0099FF',
      });
    };

    const { email, password } = user;

    if (email.trim() === '') {
      showErrorAlert('Please enter your email');
      return;
    }

    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())) {
      showErrorAlert('Please enter a valid email address');
      return;
    }

    if (password === '') {
      showErrorAlert('Please enter a password');
      return;
    }

    if (password.length < 6) {
      showErrorAlert('Password should be at least 6 characters long');
      return;
    }

    dispatch(login(user));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createUser(user);
  };

  const { waiting, success, message, resendEmail } = useSelector(state => state.main.login)

  useEffect(() => {
    if (success > 0) {
      navigate('/');
    }
    if (success < 0) {
      if (resendEmail) {
        SweetAlert.fire({
          imageUrl:
              '/assets/error-icon.png',
          imageHeight: '100px',
          title: 'Oops...',
          text: message || 'Can\'t connect with server. Please try again.',
          confirmButtonColor: '#0099FF',
          showCancelButton: true,
          confirmButtonText: "Send email again!",
          cancelButtonText: "Cancel",
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            axios.post(`${API_URL}/api/auth/send-verification-email`, {email: user?.email}).then((res) => {
              let title = '', text = '', icon = '';
              if (!res.data?.emailSent) {
                title = "Email Already Sent!";
                text = "An email has already been sent to your account. Try again in 30 minutes."
                icon = "info"
              } else {
                title = "Email Sent!";
                text = "An email has been sent to your account.";
                icon = "success"
              }
              SweetAlert.fire({
                title: title,
                text: text,
                icon: icon,
                confirmButtonColor: '#0099FF',
              })
            })
          }
          });
      } else {
        SweetAlert.fire({
          imageUrl:
              '/assets/error-icon.png',
          imageHeight: '100px',
          title: 'Oops...',
          // text: 'Your message could not be sent!',
          text: message || 'Can\'t connect with server. Please try again.',
          confirmButtonColor: '#0099FF',
        });
      }
    }
    dispatch(initLogin());
  }, [success, navigate, dispatch, message])



  return (
    <div className='sign-up-container'>
      <div className="child-profile bd-grey">
        <div className="child-profile-container">
          <h1>Sign In</h1>
          <Box
            component="form"
            className="box"
            onSubmit={handleSubmit}
            sx={{
              '& .MuiTextField-root': { m: 1, maxWidth: '100%' },
              '& .captcha': { m: 1, mt: 2 },
              '& .heading': { m: 1, textAlign: 'center' },
            }}
            noValidate
          >
            <div className="form">
              <hr />
              <br />
              <div style={{ width: '100%' }} className='name-box'>
                <TextField
                  id="email-input"
                  label="Email"
                  type="email"
                  style={{ flex: 1 }}
                  value={user?.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
              </div>
              <div style={{ width: '100%' }} className='name-box'>
                <TextField
                  id="password-input"
                  label="Password"
                  type='password'
                  style={{ flex: 1 }}
                  className="text-field"
                  value={user?.password}
                  inputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  onChange={(e) => setUser({ ...user, password: e.target.value })}
                />
              </div>
              {/* <div style={{ width: "100%" }} className="name-box">
                <FormControl fullWidth style={{ margin: "8px" }}>
                  <InputLabel id="account-type-label">Account Type</InputLabel>
                  <Select
                    labelId="account-type-label"
                    id="account-type"
                    value={user.isGuest ? "guest" : "tutor"}
                    label="Account Type"
                    style={{ flex: 1 }}
                    onChange={(e) =>
                      setUser({ ...user, isGuest: e.target.value === "guest" })
                    }
                  >
                    <MenuItem value="tutor">Tutor</MenuItem>
                    <MenuItem value="guest">Guest</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              <p style={{ width: '100%', padding: '0.5rem', textAlign: 'left', margin: '0' }}>
                <Link to='/forgot_password' className={`${config['primary-text-colour-class']} link`}> Forgot Password </Link>
              </p>
              <span style={{ width: '100%', padding: '0.5rem' }} className='name-box'>
                <LoadingButton
                  type='submit'
                  loading={waiting}
                  variant="contained"
                  size="large"
                  style={{ flex: 1 }}
                >
                  <span>Submit</span>
                </LoadingButton>
              </span>
              <p style={{ width: '100%', padding: '0.5rem', textAlign: 'center', margin: '0' }}>
                New user?  <Link to={"/signup" + location.search} className={`${config['primary-text-colour-class']} link`}> Create an account </Link>
              </p>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
