import Webcam from "react-webcam";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import React from "react";
import {CameraAlt, Replay} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {setCapturedPhoto} from "../../../store/setProfile";

const videoConstraints= {
    width: '300px',
    height: '300px',
    facingMode: "user"
};
const WebcamCapture = (props) => {
    const {open, handleClose} = props
    const dispatch = useDispatch()
    const [image, setImage] = React.useState(null)
    const handleClickAgain = () => {
        setImage(null)
    }

    const handleEditImage = () => {
        dispatch(setCapturedPhoto(image))
        handleClose()
    }

    React.useEffect(() => {
        handleClickAgain()
    }, [])

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Capture Image</DialogTitle>
            <DialogContent style={{overflowX: "hidden"}} className="dialog-body">
                {
                    image ? <Box sx={{ textAlign: 'center' }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <img src={image} alt="img" />
                        </Box>
                        <Button
                            onClick={handleClickAgain}
                            sx={{ mt: 3 }}
                            endIcon={<Replay />}
                            variant="outlined"
                        >
                            Capture Again
                        </Button>
                    </Box> : <Webcam
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                >
                    {({getScreenshot}) => (
                        <Box sx={{ textAlign: 'center', mt: 3 }}>
                            <IconButton aria-label="capture"
                                color="primary"
                                style={{ background: 'lightGray' }}
                                onClick={() => {
                                    const imageSrc = getScreenshot()
                                    setImage(imageSrc)
                                }}
                            >
                                <CameraAlt />
                            </IconButton>
                        </Box>
                    )}
                </Webcam>
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Cancel
                </Button>
                <Button autoFocus disabled={!image} onClick={handleEditImage}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WebcamCapture